import '../src/less/ss-default-styles.less';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { CookiesProvider } from 'react-cookie';
import App from 'containers/App';
import { ssSessionUtils, ssCommonUtils } from '@bit/ui-common.ui-components/ss-utils';

const { htmlStorage } = ssSessionUtils;

window.log = ssCommonUtils.log;
window.session = htmlStorage().get('ssSession');
ReactDOM.render(
    <Provider store={store}>
        <LocaleProvider locale={enUS}>
            <CookiesProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CookiesProvider>
        </LocaleProvider>
    </Provider>,
    document.querySelector('#app')
);
