import { Container } from '@bit/ui-common.ui-components/ss-components';
import { ssFormUtils, ssCommonUtils, ssSessionUtils } from '@bit/ui-common.ui-components/ss-utils';
import { Icon } from 'antd';

const 
{ formRedirect } = ssFormUtils,
{ setEnvPrefix } = ssCommonUtils,
{ htmlStorage } = ssSessionUtils;

export default Container({ services: [] },
class extends React.Component {
    constructor(props) { 
        super(props); 
        this.callSession();
    }

    callSession = () => {
        const 
        path = setEnvPrefix('/session/check'), 
        session = htmlStorage().get('ssSession'),
        token = session == null ? '' : session.token;

        setTimeout(() => {
            if (session == null) formRedirect(path, { token });
            else formRedirect(path, { token });
        }, 1800);
    }

    render() {
        return (
            <div>
                <div className='ss-index-centered ss-index-loading-card'>
                    <Icon type='loading' style={{ fontSize: '244px', color: 'rgb(150, 150, 150)' }} />
                </div>
                <div className='ss-index-centered'>
                    <img 
                        src={setEnvPrefix('/index/images/dhs-logo-02.png')} 
                        style={{ width: '200px' }} 
                        alt='DHS TSA'
                    />
                </div>
            </div>
        );
    }
});